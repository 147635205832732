@import "../Mixins";

.options {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    row-gap: 3.75rem;

    &-emailReceipt {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        margin-top: 2rem;

        &_check {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            align-items: center;


            &--input {
                /* Double-sized Checkboxes */
                -ms-transform: scale(1.5);
                /* IE */
                -moz-transform: scale(1.5);
                /* FF */
                -webkit-transform: scale(1.5);
                /* Safari and Chrome */
                -o-transform: scale(1.5);
                /* Opera */
                transform: scale(1.5);
                padding: 10px;
                margin-top: .35rem;
                background-color: blue;
                cursor: pointer;
            }

            &--text {
                cursor: pointer;
                font-size: var(--fs-text);
                font-family: 'Gellix-Regular', sans-serif;
            }

            &--fieldInput {
                padding: 1rem;
                font-size: var(--fs-text);
                font-family: 'Gellix-Regular', sans-serif;

                border: .1rem solid #D5D5D5;
                border-radius: 1rem;
            }
        }

    }

    &-payments {
        display: flex;
        flex-direction: column;
        gap: 2rem;

        &_title {
            font-size: var(--fs-title);
            font-family: 'Gellix-SemiBold', sans-serif;
        }


        .payoption {
            display: grid;
            grid-template-columns: min-content 1fr;
            grid-template-rows: 1fr;
            align-items: center;

            background-color: transparent;


            border: .1rem solid #D5D5D5;
            border-radius: 1rem;

            padding: 1rem;

            cursor: pointer;

            transition: background-color .2s ease;
            pointer-events: all;


            &:disabled {
                cursor: not-allowed;
                opacity: .5;

            }


            &-left {
                display: flex;
                flex-direction: row-reverse;
                align-items: center;
                gap: 1rem;


                &_text {
                    font-size: var(--fs-text-big);
                    font-family: 'Gellix-Regular', sans-serif;
                }


                .circleContainer {
                    display: grid;
                    place-items: center;

                    height: 3rem;
                    width: 3rem;
                    border-radius: 50%;
                    border: solid .1rem #D5D5D5;

                    .checkContainer {
                        font-size: var(--fs-text-big);
                        display: flex;
                        align-items: center;

                    }
                }

                .dark {
                    background-color: var(--primary-hover);
                    border: none;
                }
            }

            &-vippsLogo {
                justify-self: end;
            }

            &-cardLogo {
                justify-self: end;
                font-size: 2.5rem;

                display: flex;
                align-items: center;
                margin-right: .25rem;
                color: #000;
            }

        }

        .chosenPayment {
            background-color: var(--primary);
            // border: none;
        }
    }

    .paybutton {
        font-family: 'Gellix-Semibold', sans-serif;
        font-size: var(--fs-text);
        border: none;
        border-radius: 1rem;

        transition: all .2s ease;
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 1.75rem;
        height: min-content;

    }


    button.NONE {
        background-color: transparent;
        color: #848484;

        border: .1rem solid #4A4A4A;

        &:focus {
            border: solid .2rem #000;
        }
    }

    button.ОК {
        background-color: var(--primary);
        color: #fff;
        cursor: pointer;

        &:hover {
            background-color: var(--primary-hover);
        }
    }
}