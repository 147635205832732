@import "../Mixins";

.TicketChooser {
    display: flex;
    grid-column-gap: 1rem;
    column-gap: 1rem;
    grid-template-rows: 1fr;
    justify-content: space-between;

    &-info {
        display: flex;
        flex-direction: column;
        justify-items: flex-start;
        align-self: center;

        width: max-content;

        &--title {
            font-size: var(--fs-text-big);
            font-family: 'Gellix-SemiBold', sans-serif;
            color: #000;
            margin-bottom: .75rem;
        }

        &--price {
            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;
            color: var(--textColor);
        }

        &--requirements {
            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;
            color: var(--textColor);

        }
    }

    &-btns {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        justify-self: end;
        align-self: center;

        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        &--btn {
            font-size: var(--fs-heading);
            font-family: 'Gellix-SemiBold', sans-serif;
            background-color: var(--primary);
            color: #fff;
            border-radius: 50%;
            border: none;
            height: 3.5rem;
            width: 3.5rem;
            display: grid;
            place-self: center;
            cursor: pointer;
            transition: background-color .2s ease;

            &:hover {
                background-color: var(--primary-hover);
            }

            &_minus {
                place-self: center;
            }

            &_plus {
                place-self: center;
            }
        }

        &--quantity {
            font-size: var(--fs-title);
            font-family: 'Gellix-Bold', sans-serif;
            min-width: 2rem;
            text-align: center;
        }
    }
}