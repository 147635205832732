@import "./Mixins";

.Home {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;

    margin-top: 5rem;

    min-height: min-content;

    &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 100px 1fr;
        gap: 1rem;

        position: relative;
        height: 100%;
        width: 100%;
        max-width: var(--max-width);
        margin: 0 auto;
        z-index: 1;

        @include lg() {
            width: 90vw;
        }

        &_loader {
            grid-row: 2 / 2;
            place-self: center;
        }

        &_title {
            align-self: center;
            justify-self: flex-start;
            grid-column: 1 / 1;
            grid-row: 1 / 1;

            font-size: var(--fs-heading);
            font-family: 'Gellix-Regular', sans-serif;
            letter-spacing: .1rem;
            font-weight: 300;
        }

        &_teamWrap {
            grid-column: 1 / 1;
            grid-row: 2 / 2;
            align-items: center;
            justify-content: center;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            gap: 3rem;
            width: 100%;

            &--team {
                position: relative;

                display: grid;
                grid-template-columns: 150px 1fr 50px;
                grid-template-rows: 1fr;

                height: 13.5rem;
                width: 100%;
                align-items: stretch;
                background-color: #ffff;
                box-shadow: var(--shadow1);

                @include md() {
                    height: 15rem;
                }

                @include sm() {
                    height: 13rem;
                }

                @include sm() {
                    grid-template-columns: 100px 1fr 50px;
                }

                &-leftBox {
                    grid-column: 1 / 1;
                    grid-row: 1 / 1;
                    height: 100%;
                    width: 100%;

                    display: grid;
                    place-items: center;

                    &_image {
                        height: 10rem;

                        @include sm() {
                            height: 7rem;
                        }
                    }
                }

                &-title {
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;

                    place-self: flex-start;
                    margin-left: 2rem;
                    margin-top: 1.5rem;

                    text-decoration: none;
                    color: #000;

                    font-size: var(--fs-title);
                    font-family: 'Gellix-Regular', sans-serif;
                    font-weight: 500;
                    letter-spacing: .03rem;

                    @include md() {
                        font-size: var(--fs-text-big);
                    }

                    @include sm() {
                        font-size: var(--fs-text);
                    }
                }

                &-buyTicket {
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;
                    align-self: center;
                    justify-self: flex-start;
                    margin-left: 2rem;

                    text-decoration: none;
                    color: #000;

                    font-size: var(--fs-text);

                    @include sm() {
                        font-size: 1rem;
                        margin-bottom: 2.5rem;
                    }
                }

                &-toBuy {
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;
                    align-self: center;
                    justify-self: end;

                    text-decoration: none;
                    color: #616161;

                    font-size: var(--fs-text);
                    font-weight: 300;

                    @include lg() {
                        align-self: end;
                        justify-self: flex-start;

                        margin-left: 2rem;
                        margin-bottom: 1.5rem;
                    }

                    @include sm() {
                        font-size: 1rem;
                        font-weight: 500;
                        letter-spacing: .03rem;
                    }
                }

                &-rightArrow {
                    grid-column: 3 / 3;
                    grid-row: 1 / 1;
                    place-self: center;

                    transform: rotate(-90deg);

                }
            }

            &--teamLink {
                text-decoration: none;

                &:last-child {
                    margin-bottom: 4rem;
                }
            }
        }
    }
}