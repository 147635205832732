@import "./Mixins";

.overlay {
    z-index: 9999999;
    background-color: rgba(0, 0, 0, .5);
    position: fixed;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    width: 100vw;

    cursor: pointer;

    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.cartWrap {
    pointer-events: auto;
    z-index: 9999999;
    position: absolute;
    bottom: 0;

    height: 34rem;
    width: 100vw;
    background-color: #fff;

    border-top-right-radius: 2rem;
    border-top-left-radius: 2rem;

    & .mobileCart {
        height: 100%;
        grid-template-columns: 1fr;
        grid-template-rows: repeat(5, min-content);
        padding: 2rem 1.5rem;

        &-matchInfo {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            border-bottom: .2rem #F1F3F7 solid;
            border-radius: .1rem;
            padding-bottom: 1rem;

            &_title {}

            &_info {
                font-size: 1.65rem;
                font-family: 'Gellix-SemiBold', sans-serif;
            }

            &_date {
                font-size: 1.4rem;
                font-family: 'Gellix-Light', sans-serif;
                color: var(--darkGray);
            }
        }

        &-seats {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            border-bottom: .2rem #F1F3F7 solid;
            border-radius: .1rem;
            padding: 1rem 0rem;

            &_seats {}
        }

        &-tickets {
            display: flex;
            flex-direction: column;
            gap: .5rem;

            border-bottom: .2rem #F1F3F7 solid;
            border-radius: .1rem;
            padding: 1rem 0rem;

            &_wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: .5rem;


                font-size: var(--fs-text);

                &--quantity {
                    font-family: 'Gellix-Bold', sans-serif;
                }

                &--price {
                    font-family: 'Gellix-Regular', sans-serif;
                }
            }
        }

        &-totalPrice {
            display: flex;
            flex-direction: column;
            gap: .5rem;
            padding: 1rem 0rem;

            &_price {
                font-family: 'Gellix-Bold', sans-serif;
                font-size: var(--fs-title);
            }
        }

        .smallTitle {
            font-size: 1.2rem;
            font-family: 'Gellix-Regular', sans-serif;
            color: var(--darkGray);
        }
    }

    & .closeCart {
        height: 4rem;
        width: 4rem;

        border-radius: 50%;
        border: none;

        right: 2rem;
        top: -5rem;
        position: absolute;

        display: flex;
        align-items: center;
        justify-content: center;

        &_icon {
            height: 1.5rem;
            width: 1.5rem;
        }
    }
}