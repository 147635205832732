@import "../Mixins";

.summary {
    display: grid;
    grid-template-columns: 1fr;
    grid-auto-rows: minmax(min-content, max-content);
    row-gap: 3.75rem;

    &-matchInfo {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_match {
            font-size: var(--fs-text);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_matchDate {
            font-size: var(--fs-text);
        }
    }

    &-seats {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_title {
            font-size: var(--fs-title);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_chosen {
            display: flex;
            flex-direction: row;
            gap: .5rem;
            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;

            &--fields {}

            &--rows {}

            &--seats {}
        }
    }

    &-quantity {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_title {
            font-size: var(--fs-title);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_info {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-family: 'Gellix-Regular', sans-serif;

            &-Wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: .5rem;


                font-size: var(--fs-text);

            }
        }
    }

    &-fees {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_title {
            font-size: var(--fs-title);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_info {
            display: flex;
            flex-direction: column;
            gap: 1rem;
            font-family: 'Gellix-Regular', sans-serif;

            &-Wrap {
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                gap: .5rem;


                font-size: var(--fs-text);

            }
        }
    }


    &-discountButton {
        border: none;
        background-color: transparent;
        display: flex;
        justify-content: flex-start;
        font-family: 'Gellix-Regular', sans-serif;
        font-size: var(--fs-text);

        color: var(--primary);

        text-decoration: underline;
        text-underline-offset: .3rem;
        text-decoration-thickness: .2rem;

        transition: color .2s ease;

        cursor: pointer;

        &:hover {
            color: var(--primary-hover);
        }
    }

    &-discountInput {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-template-rows: 1fr;
        gap: 1rem;

        &_title {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            font-size: var(--fs-text-big);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_removeBtn {
            grid-column: 2 / 2;
            grid-row: 1 / 1;
            color: var(--discountRed);
            background: transparent;

            text-decoration: none;
            border: none;

            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;

            justify-self: flex-end;
            cursor: pointer;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: .2rem;
            }
        }

        &_input {
            grid-row: 2 / 2;

            font-size: var(--fs-text);
            font-family: 'Gellix-Medium', sans-serif;
            padding: .5rem 1rem;
            border-radius: .8rem;
            border: solid .15rem #cecece;

            &:focus,
            &:active {
                box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px, rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
            }

            &:disabled {
                cursor: default;
            }
        }

        &_addDiscountBtn {
            grid-column: 2 / 2;
            grid-row: 2 / 2;

            font-size: var(--fs-text);
            font-family: 'Gellix-SemiBold', sans-serif;
            padding: 0 1rem;
            border-radius: .8rem;
            border: solid .15rem #cecece;

            background-color: transparent;

            cursor: pointer;

            &:disabled {
                cursor: default;
            }
        }
    }


    &-campaign {
        display: grid;
        grid-template-columns: 1fr max-content;
        grid-auto-rows: minmax(min-content, max-content);
        gap: .5rem;

        &_title {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            font-size: var(--fs-title);
            font-family: 'Gellix-SemiBold', sans-serif;
        }

        &_discountTitle {

            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;
        }

        &_discountPrice {
            font-size: 1.6rem;
            font-family: 'Gellix-Regular', sans-serif;
        }

        &_removeBtn {
            grid-column: 2 / 2;
            grid-row: 1 / 1;
            color: var(--discountRed);
            background: transparent;

            text-decoration: none;
            border: none;

            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;

            justify-self: flex-end;
            cursor: pointer;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            svg {
                margin-top: .2rem;
            }
        }
    }

    .wrongDiscountCode {
        color: var(--discountRed);
        font-family: 'Gellix-SemiBold', sans-serif;
    }

    &-prices {
        display: flex;
        flex-direction: column;
        gap: .5rem;


        &_partialPrice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;
        }

        &_discountPrice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            font-size: 1.6rem;
            font-family: 'Gellix-Regular', sans-serif;

            & span {
                color: var(--discountRed);
            }
        }

        &_package {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            font-size: 1.6rem;
            font-family: 'Gellix-Regular', sans-serif;

            & span {
                color: var(--discountRed);
            }
        }

        &_finalPrice {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            margin-top: 1rem;

            span {
                font-size: 2rem;
                font-family: 'Gellix-SemiBold', sans-serif;
            }
        }
    }
}