@import "./Mixins";

.TicketOrderMobile {
    position: relative;

    &-btn {
        align-items: flex-start;
        font-family: 'Gellix-Semibold', sans-serif;
        font-size: var(--fs-text);

        width: 100%;
        max-width: 40.9rem;

        margin: 0 auto;

        height: 4.8rem;
        border: none;
        border-radius: 1rem;

        transition: background-color .2s ease;
        text-decoration: none;

        display: flex;
        justify-content: center;
        align-items: center;

        margin: 0rem 1rem;

        background-color: #fff;

        @include sm() {
            max-width: 31rem;
            position: fixed;
            bottom: 2rem;
        }
    }

    &-cart {
        @include sm2 {
            position: absolute;
        }

        @include s400 {
            height: 4rem;
            left: 1rem;
        }

        left: 2.5rem;
        bottom: 10rem;

        display: flex;
        align-items: center;
        gap: 2rem;

        background-color: #fff;

        height: 6rem;

        padding: 0 2rem;

        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        border: none;

        &_price {
            color: #000;
            font-size: var(--fs-text-big);
            font-family: 'Gellix-Light', sans-serif;

            @include s400 {
                font-size: var(--fs-text);
            }
        }

        &_icon {
            height: 3rem;
            width: 3rem;

            @include s400 {
                height: 2rem;
                width: 2rem;
            }
        }
    }

    &-map {
        @include sm2 {
            position: absolute;
        }

        @include s400 {
            height: 4rem;

            right: 1rem;
        }

        right: 2.5rem;
        bottom: 10rem;

        display: flex;
        align-items: center;
        gap: 1rem;

        background-color: #000;

        height: 6rem;

        padding: 0 2.5rem;

        box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);
        border-radius: 20px;
        border: none;

        &_text {
            color: #fff;
            font-size: var(--fs-text-big);
            font-family: 'Gellix-Light', sans-serif;

            @include s400 {
                font-size: var(--fs-text);
            }
        }

        &_icon {
            fill: #fff;
            height: 3rem;
            width: 3rem;

            @include s400 {
                height: 2rem;
                width: 2rem;
            }
        }
    }
}