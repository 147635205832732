@use "../TicketOrder";
@use "../TicketNavigation";
@import "../Mixins";

.rows {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;

    margin-top: 5rem;

    &-container {
        display: grid;
        grid-template-columns: 440px 1fr;
        grid-template-rows: 1fr 125px;

        position: relative;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        z-index: 1;

        @include xl {
            grid-template-rows: 1fr 80px;
        }

        @include sm2 {
            grid-template-columns: 1fr;
        }

        &_img {
            grid-column: 2 / 2;
            grid-row: 1 / 1;

            height: auto;
            width: 50%;

            place-self: center;

            object-fit: cover;
            object-position: center;
        }

    }
}