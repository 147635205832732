@import "./Mixins";

.ClubTerms {
    width: 100vw;

    padding-bottom: 5rem;

    min-height: fit-content;

    margin-top: 8rem;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 7rem;

        width: 100%;
        max-width: var(--max-width);
        margin: 0 auto;
        z-index: 1;

        @include xl {
            padding: var(--pagePaddingSides);
        }



        h1 {
            font-size: var(--fs-heading-big);
            font-family: 'Gellix-Regular', sans-serif;
        }


        h2 {
            font-size: var(--fs-heading);
            font-family: 'Gellix-Regular', sans-serif;

        }

        span {

            h3 {
                font-size: var(--fs-title);
                font-family: 'Gellix-Regular', sans-serif;
            }

            p {
                font-size: var(--fs-text-big);
                font-family: 'Gellix-Regular', sans-serif;
                white-space: pre-line;
            }
        }

        Markup {
            font-size: var(--fs-text-big);
                font-family: 'Gellix-Regular', sans-serif;
        }

        &-text {
            font-size: var(--fs-text-big);
                font-family: 'Gellix-Regular', sans-serif;
        }

    }
}