@import "./Mixins";

.Login {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;

    margin-top: 5rem;

    min-height: min-content;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 7em;

        position: relative;
        height: 100%;
        width: 100%;
        max-width: var(--max-width-login);
        margin: 0 auto;
        z-index: 1;

        margin-top: 10rem;

        @include sm() {
            padding: var(--pagePaddingSidesMobile);
        }

        &_options {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 2rem;

            & button {
                padding: 1rem 3rem;
            }

            &-register {
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                gap: .75rem;
            }
        }

        & .resetPassword {
            display: flex;
            flex-direction: column;
            gap: 3rem;



            &-container {
                display: flex;
                flex-direction: column;
                gap: .75rem;
            }
        }

        &_textWrap {
            place-self: center;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: .5fr 1fr;

            row-gap: 1.5rem;

            &--title {
                font-family: 'Gellix-Bold', sans-serif;
                font-size: 4.2rem;
            }

            &--text {
                font-family: 'Gellix-Regular', sans-serif;
                font-size: var(--fs-text);

                color: #4B4B4B;

                line-height: 1.375;

                width: 9 0%;
            }
        }

        &_Form {
            display: flex;
            flex-direction: column;
            width: 100%;

            grid-column: 1 / 1;
            grid-row: 2 / -1;

            align-self: center;

            gap: 2.5rem;

            margin-bottom: 10rem;

            label {
                color: var(--textColor);
                font-family: 'Gellix-Medium', sans-serif;
                font-size: var(--fs-text);
            }

            &--inputWrapEmail {
                display: flex;
                flex-direction: column;
                gap: 2.5rem;

                grid-row: 2 / 2;


                &-div {
                    display: flex;
                    flex-direction: column;
                    gap: .5rem;
                }
            }

            &--inputWrap {
                display: flex;
                flex-direction: column;
                gap: .8rem;

                & .phoneInput {
                    display: flex;
                    flex-direction: row;
                    height: 5rem;

                    .countryCode {
                        width: 7rem;

                        border-top-left-radius: var(--borderRadius) !important;
                        border-bottom-left-radius: var(--borderRadius) !important;
                        border-top-right-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                        border-right: none !important;
                        text-align: center !important;
                    }

                    .PhoneInputInput {
                        width: 100%;
                        height: 100%;
                        border-top-left-radius: 0 !important;
                        border-bottom-left-radius: 0 !important;
                        border-top-right-radius: var(--borderRadius);
                        border-bottom-right-radius: var(--borderRadius);
                    }
                }
            }


            &_verificationWrap {
                display: flex;
                flex-direction: column;
                align-items: flex-start;
                justify-content: flex-start;
                gap: 1rem;

                margin-bottom: 6rem;



                &--verificationText {
                    color: var(--textColor);
                    font-family: 'Gellix-Medium', sans-serif;
                    font-size: var(--fs-text);

                }

                &--inputWrap {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;
                    width: 100%;


                    &-verificationInput {
                        border: solid #DEDDDB .1rem;
                        border-radius: var(--borderRadius);

                        background-color: transparent;

                        color: #000;
                        font-family: 'Gellix-Medium', sans-serif;
                        font-size: 1.6rem;
                        padding-left: 1rem;

                        width: 100%;
                        height: 5rem;

                    }

                    &-sendNewBtn {
                        background-color: transparent;
                        color: var(--primary);
                        border: none;

                        font-family: 'Gellix-Medium', sans-serif;
                        font-size: var(--fs-text);



                        cursor: pointer;
                    }

                    &-continue {

                        &_btnOK {
                            margin-bottom: 4rem;

                            padding: 1rem 3rem;
                        }

                        &_btnNONE {
                            margin-bottom: 4rem;

                            padding: 1rem 3rem;

                        }
                    }
                }
            }


        }
    }
}

.countryCode {
    display: flex;
    flex-direction: column;

    &-input {
        width: 6rem;
    }
}