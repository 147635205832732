@import "./Mixins";

.register {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;

    margin-top: 5rem;

    min-height: fit-content;

    &-container {
        display: flex;
        flex-direction: column;
        gap: 7em;

        position: relative;
        height: 100%;
        width: 100%;
        max-width: var(--max-width-login);
        margin: 0 auto;
        z-index: 1;

        margin-top: 10rem;
        margin-bottom: 10rem;

        @include sm() {
            padding: var(--pagePaddingSidesMobile);
            margin-bottom: 15rem;
        }

        & .registerText {
            place-self: center;

            display: flex;
            flex-direction: column;
            gap: 1.5rem;

            h1 {
                font-family: 'Gellix-Bold', sans-serif;
                font-size: 4.2rem;
            }

            p {
                font-family: 'Gellix-Regular', sans-serif;
                font-size: var(--fs-text);

                color: #4B4B4B;

                line-height: 1.375;

                width: 90%;
            }
        }

        & .registerForm {
            display: flex;
            flex-direction: column;
            gap: 2rem;

            &-container {
                display: flex;
                flex-direction: column;
                gap: .8rem;
            }

            & .phoneInputs {
                display: flex;
                flex-direction: column;
                gap: .8rem;


                &-inputs {
                    display: flex;
                    flex-direction: row;
                    height: 5rem;

                    .countryCode {
                        width: 7rem;

                        border-top-left-radius: var(--borderRadius) !important;
                        border-bottom-left-radius: var(--borderRadius) !important;
                        border-top-right-radius: 0 !important;
                        border-bottom-right-radius: 0 !important;
                        border-right: none !important;
                        text-align: center !important;
                    }

                    .phone {
                        width: 100%;
                        border-top-left-radius: 0 !important;
                        border-bottom-left-radius: 0 !important;
                        border-top-right-radius: var(--borderRadius);
                        border-bottom-right-radius: var(--borderRadius);
                    }
                }
            }

            & .names {
                display: flex;
                flex-direction: column;
                gap: .8rem;
                width: 100%;


                &-inputs {
                    display: flex;
                    flex-direction: row;
                    height: 5rem;
                    width: 100%;

                    & .firstName {
                        width: 50%;

                        border-top-left-radius: var(--borderRadius);
                        border-bottom-left-radius: var(--borderRadius);
                        border-top-right-radius: 0;
                        border-bottom-right-radius: 0;
                        border-right: none;
                    }

                    & .lastName {
                        width: 50%;

                        border-top-left-radius: 0;
                        border-bottom-left-radius: 0;
                        border-top-right-radius: var(--borderRadius);
                        border-bottom-right-radius: var(--borderRadius);
                    }
                }
            }

        }

        span {
            color: red;
            font-weight: 800;
            font-size: 1.2rem;

        }

    }
}

.checkbox-container {
    display: flex;
    align-items: center;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    height: min-content;
    font-size: 1.4rem;
    min-height: 25px;

    @include md {
        padding-left: 5rem;
    }
}

.checkbox-container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

.checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    border-radius: 0.6rem;
    background-color: #DEDDDB;
    transition-duration: 0.1s;

    @include md {
        top: 50%;
        transform: translate(0, -50%);
    }
}

.checkbox-container:hover input~.checkmark {
    background-color: #ccc;
}

.checkbox-container input:checked~.checkmark {
    background-color: var(--primary);
}

.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

.checkbox-container input:checked~.checkmark:after {
    display: block;
}

.checkbox-container .checkmark:after {
    left: 10px;
    top: 7px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}