@import "../Mixins";

.PurchaseHistory {
    width: 100vw;

    padding-bottom: 5rem;

    min-height: fit-content;

    &-container {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 200px min-content 1fr;

        width: 100%;
        max-width: var(--max-width);
        z-index: 1;

        @include lg() {
            width: 90vw;
        }

        &_loader {
            grid-row: 3 / 3;

            place-self: center;

            margin-top: 5rem;
        }

        &_tickets {
            height: 7rem;
            width: 100vw;

            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            gap: 10px;

            place-self: center;

            @include lg() {
                padding: 0rem 2rem;
            }

            &-header {
                display: flex;
                align-items: center;
                max-width: var(--max-width);
                width: 100%;

                & h1 {
                    font-size: var(--fs-text-big);
                    font-family: 'Gellix-Normal', sans-serif;
                    font-weight: 700;

                    @include sm() {
                        font-size: 1.75rem;
                    }
                }

                &--optionList {
                    cursor: pointer;
                    padding: 1rem;
                    border: 1px solid #e7e2e2;


                }
            }

            &-buttons {
                display: flex;
                align-items: center;
                max-width: var(--max-width);
                width: 100%;
                gap: 5px;

                @include lg() {
                    margin-left: -.5rem;
                }
            }

            &-button {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px 15px;

                color: #6C6C6C;
                background: transparent;
                border: 1px solid #6C6C6C;
                box-sizing: border-box;
                border-radius: 18px;

                cursor: pointer;

            }

            &-button_selected {
                background: #6C6C6C;
                color: #FFFFFF;
            }
        }

        &_ticketList {
            grid-row: 3 / 3;
            place-items: center;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            max-width: var(--max-width);
            row-gap: .75rem;

            margin: 0 auto;

            @include lg() {
                padding: var(--pagePaddingSides);
            }

            @include md() {
                padding: 0rem 2rem;
            }

            & .splitTicket {
                display: grid;
                grid-template-rows: 1fr;
                grid-template-columns: repeat(2, 1fr);

                justify-items: space-between;
                align-items: center;

                @include sm {
                    grid-template-columns: 1fr;
                    grid-template-rows: repeat(2, 1fr);
                    justify-items: center;

                    button.seeTicketsBtn {
                        justify-self: start;
                    }
                }

                &:hover>.seeTicketsBtn {
                    background-color: var(--primary-hover);
                }

                button.seeTicketsBtn {
                    white-space: nowrap;
                }
            }

            &-ticket {
                position: relative;

                display: flex;
                flex-direction: column;
                gap: 1rem;

                min-height: 12rem;
                width: 100%;

                background-color: #ffff;
                box-shadow: var(--shadow2);

                border-radius: var(--borderRadius);

                padding: 1.75rem 2rem 2rem 2rem;

                transition: all .2s ease;

                & .ticketInfoWrap {
                    position: relative;

                    display: flex;
                    flex-direction: column;
                    gap: 1rem;

                    min-height: 12rem;
                    width: 100%;

                }



                &:hover {
                    box-shadow: var(--shadow4);
                }


                & .seeTicketsBtn {
                    width: fit-content;
                    padding: 1rem 5rem;
                    justify-self: end;
                    align-self: end;
                }




                &_teamName {
                    display: inline;

                    font-size: var(--fs-title);
                    font-family: 'Gellix-SemiBold', sans-serif;
                    font-weight: 400;
                    color: #000;

                    text-transform: capitalize;

                    @include md() {
                        font-size: var(--fs-text-big);
                    }

                    @include sm() {
                        font-size: 1.7rem;
                    }
                }

                &_startDate {
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;

                    align-items: center;

                    @include sm() {
                        gap: .5rem
                    }

                    &--date {


                        font-size: var(--fs-text);
                        font-family: 'Gellix-Medium', sans-serif;
                        font-weight: 300;
                        color: #646464;

                        @include sm() {
                            font-size: 1.2rem;
                        }
                    }
                }

                &_address {
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;

                    align-items: center;

                    @include sm() {
                        gap: .5rem
                    }

                    &--location {


                        font-size: var(--fs-text);
                        font-family: 'Gellix-Medium', sans-serif;
                        font-weight: 300;
                        color: #646464;

                        @include sm() {
                            font-size: 1.2rem;
                        }
                    }
                }

                &_tickets {
                    display: flex;
                    flex-direction: column;
                    gap: 2rem;



                    @include sm() {
                        gap: .5rem
                    }

                    &--amount {
                        display: flex;

                        align-items: center;
                        gap: .5rem;


                        font-size: var(--fs-text);
                        font-family: 'Gellix-Medium', sans-serif;
                        font-weight: 300;
                        color: #646464;

                        @include sm() {
                            font-size: 1.2rem;
                        }
                    }
                }

                &_filters {
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;
                    align-self: flex-start;
                    justify-self: flex-start;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    column-gap: 1rem;

                    margin-top: 1rem;

                    &-today {
                        background-color: var(--primary-red);
                        color: #fff;
                        padding: .25rem 1.25rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        @include sm() {}
                    }

                    &-tomorrow {
                        background-color: var(--primary);
                        color: var(--primary);
                        padding: .5rem .5rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        &-text {
                            color: var(--primary);
                        }

                        @include sm() {}
                    }

                    &-fewTickets {
                        background-color: var(--primary-orange);
                        color: #fff;
                        padding: .25rem 1.25rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        @include sm() {}
                    }

                    &-toBePaid {
                        background-color: var(--primary-orange);
                        color: var(--primary-orange);
                        padding: .5rem .5rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        &-text {
                            color: var(--primary-orange);
                        }

                        @include sm() {}
                    }
                }

                &_ticketBtn {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;

                    grid-column: 3 / 3;
                    grid-row: 1 / -1;

                    place-self: center;

                    background-color: var(--primary);
                    height: 40%;
                    width: 75%;

                    border: none;
                    border-radius: 5px;

                    transition: background-color .2s ease-in;

                    &:hover {
                        background-color: var(--primary-hover);
                    }

                    &--text {
                        font-size: var(--fs-text);
                        font-family: 'Gellix-Bold', sans-serif;
                        font-weight: 300;
                        color: #fff;
                    }
                }

                &_mobileArrow {
                    grid-row: 1 / -1;
                    grid-column: 3 / 3;
                    place-self: center;

                    height: 1rem;
                    transform: rotate(-90deg);

                    @include sm() {
                        height: .75rem;
                    }
                }
            }

            &-ticketLink {
                margin-top: 1rem;
                text-decoration: none;
                width: 100%;

                &:disabled {
                    border: none;

                    .PurchaseHistory-container_ticketList-ticket {
                        &:hover {
                            box-shadow: var(--shadow2);
                        }

                        &_teamName {
                            text-align: left;
                        }


                    }
                }
            }
        }

        &_noResult {
            margin-top: 5rem;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 2rem;

            grid-row: 3 / 3;
            align-self: flex-start;
            justify-self: center;
            text-align: center;

            &--text {
                font-size: var(--fs-title);
            }

            &--link {
                font-size: var(--fs-text);
            }
        }
    }
}