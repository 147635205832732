.stripeContainer {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    &-cardWrap {
        display: flex;
        flex-direction: column;
        gap: .8rem;
        margin-left: 1rem;
        border-bottom: 1px solid #EEEEEE;

        & p {
            font-size: var(--fs-text);
            font-family: 'Gellix-Regular', sans-serif;
        }
    }

}