@import "./Mixins";

.Tickets-container_leftBox-topSection,
.section-container_leftBox-topSection,
.rows-container_leftBox-topSection,
.seats-container_leftBox-topSection,
.payment-container_leftBox-topSection {
    grid-row: 1 / 1;
    grid-column: 1 / 1;

    .Navigator {
        height: 100%;
        display: grid;
        grid-template-rows: 1fr 1fr min-content;

        gap: 1rem;

        margin-top: 2.4rem;

        &-top {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;

            grid-column: 1 / -1;
            grid-row: 1 / 1;
            // padding-bottom: 1.5rem;

            &--cancel {
                grid-column: 1 / -1;
                grid-row: 1 / 1;
                align-self: center;
                justify-self: end;

                height: 50%;
                background-color: transparent;
                border: none;
                font-size: var(--fs-text);
                font-family: 'Gellix-Medium', sans-serif;
                cursor: pointer;
                color: var(--primary-red);
            }

            &--title {
                grid-column: 1 / -1;
                grid-row: 1 / 1;
                align-self: center;
                justify-self: flex-start;

                font-size: var(--fs-title);
                font-family: 'Gellix-Bold', sans-serif;
            }
        }

        &-links {
            grid-column: 1 / 1;
            grid-row: 2 / 2;

            display: flex;
            flex-direction: row;
            align-items: center;

            &--crumbWrap {
                display: flex;
                flex-direction: row;
                align-self: center;
                gap: .5rem;
                // border-bottom: solid .1rem var(--lightGray);
                padding-bottom: 1rem;

                // break halfway 
                &_crumbs {
                    font-family: 'Gellix-Medium', sans-serif;
                    font-size: var(--fs-text);
                    color: var(--notVisitedCrumb);
                    text-decoration: none;
                    cursor: pointer;

                    @include sm {
                        font-size: 1.15rem;
                    }
                }
            }

            .visited {
                color: #000;
            }
        }

        &-overview {
            grid-column: 1 / 1;
            grid-row: 3 / 3;

            display: flex;
            flex-direction: row;
            align-items: center;
            gap: 1rem;

            height: 4rem;


            border-top: .1rem solid #EDEDED;


            &--backarrow {
                justify-content: flex-start;
                font-size: var(--fs-text-big);

                background-color: transparent;

                border: none;

                display: flex;
                align-items: center;

                cursor: pointer;
            }

            &_chosenWrap {
                display: flex;
                flex-direction: row;
                align-items: center;
                gap: 1rem;

                font-size: var(--fs-text);
                font-family: 'Gellix-SemiBold', sans-serif;
            }
        }

        position: relative;

        &::after {
            content: "";
            position: absolute;
            width: 100%;
            bottom: 1px;
            z-index: -1;
            transform: scale(.9);
            box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.05);
        }
    }
}