@import "./Mixins";

.mobileImgModal {
    position: absolute;
    top: 0;
    z-index: 9999999999999;

    width: 100vw;

    display: flex;
    flex-direction: column;

    &-wrap {
        position: relative;
        width: 100%;

        display: flex;
        flex-direction: column;


        &_img {
            height: auto;
            width: 100%;
        }

        &_close {
            top: 1rem;
            right: 1rem;
            position: absolute;
            align-items: flex-start;
            justify-content: flex-start;

            background-color: #000;
            border-radius: 50%;
            border: none;

            height: 4.5rem;
            width: 4.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);

            &--icon {
                height: 1.75rem;
                width: 1.75rem;
            }
        }

        &_map {
            bottom: 1rem;
            right: 1rem;
            position: absolute;
            align-items: flex-start;
            justify-content: flex-start;

            background-color: #000;
            border-radius: 50%;
            border: none;

            height: 4.5rem;
            width: 4.5rem;

            display: flex;
            align-items: center;
            justify-content: center;

            box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.25);

            &--icon {
                height: 1.75rem;
                width: 1.75rem;
            }
        }

        &_infoLabelWrap {

            &-btn {
                width: 100vw;
                background-color: #000;
                height: 4.5rem;

                display: flex;
                align-items: center;

                padding: 0rem 2rem;

                border: none;

                .infoLabelText {
                    display: flex;
                    flex-direction: row;
                    gap: 1rem;

                    &-icon {
                        background-color: #fff;
                        border-radius: 50%;
                        width: 1.75rem;
                        height: 1.75rem;

                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }

                    &-text {
                        color: #fff;
                        font-size: var(--fs-text);
                        font-family: 'Gellix-Regular', sans-serif;
                    }
                }
            }
        }


    }

}