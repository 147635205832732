@import "../Mixins";

.seatCard {
    transition: background-color .2s ease;

    border: none;

    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    padding: 1.25rem;

    height: max-content;

    background-color: rgb(227, 236, 241);

    border: none;
    border-radius: 1rem;

    cursor: pointer;

    &:last-child {
        margin-bottom: 1rem;
    }

    &-title {
        font-family: 'Gellix-Regular', sans-serif;
        letter-spacing: .1rem;
    }
}

.checkContainer {
    font-size: var(--fs-text-big);
    display: flex;
    align-items: center;
    margin-right: .3rem;
}

.circleContainer {
    display: flex;
    align-items: center;

    font-size: var(--fs-title);
    color: rgb(177, 177, 177);
}

.chosen {
    background-color: var(--primary);
    color: #fff;
}