@import "./Fonts";
@import "./Mixins";

/* ===== Universal Things ===== */
:root {

  //* COLORS
  --NavColor: #000;
  --teamHeader: #005F98;
  --primary: #0476D9;
  --primary-hover: #002341;
  --primary-red: #E23D51;
  --primary-orange: #FF9800;
  --textColor: #525252;
  --notVisitedCrumb: #b1b1b1;
  --lightGray: #D5D5D5;
  --discountRed: #FF2929;
  --darkGray: #4B4B4B;


  //* SHADOWS
  --shadow1: 0 2px 8px 0 rgb(45 53 62 / 10%);
  --shadow2: 0px 0px 15px rgba(0, 0, 0, 0.15);
  --shadow3: 0px -10px 20px rgba(0, 0, 0, .1);
  --shadow4: 0px 2px 20px rgba(0, 0, 0, .2);

  --borderRadius: 1rem;


  //* FONT-SIZES
  --fs-heading-big: 4rem;
  --fs-heading: 3rem;
  --fs-title: 2.5rem;
  --fs-text-big: 2rem;
  --fs-text: 1.52rem;

  //* WIDTHS
  --max-width: 114rem;
  --max-width-login: 50rem;
  --max-width-tickets: 95%;

  --pagePaddingSides: 0 2rem;
  --pagePaddingSidesMobile: 0 1.25rem;
}


*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}


html {
  position: relative;
  // ! This defines what 1rem is.
  font-size: 62.5%;
  box-sizing: border-box;
  min-height: 100vh;
  scroll-behavior: smooth;
}

body {
  position: relative;
  box-sizing: border-box;
  min-height: 100vh;
  overflow-x: hidden;
  scroll-behavior: smooth;
  font-family: 'Gellix-Light', sans-serif;
  background-color: #fff;
}

.nostyle {
  background: transparent;
  border: none;
}

.dispNone {
  display: none;
}

.SuspenseLoader {
  position: absolute;
  top: 50%;
  right: 50%;
  transform: translate(50%, 50%);
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

h1 {
  font-family: 'Gellix-Medium', sans-serif;
}


p,
label {
  font-family: 'Gellix-Regular', sans-serif;
  font-size: var(--fs-text);
}

button.buttonPrimary,
a.buttonPrimary {
  height: 4rem;
  font-family: 'Gellix-Medium', sans-serif;
  background-color: var(--primary);
  border: none;
  border-radius: var(--borderRadius);
  color: #fff;

  cursor: pointer;

  transition: background-color .2s ease-in;

  pointer-events: all;

  &:hover {
    background-color: var(--primary-hover);
  }

  &:focus {
    border: solid .2rem #000;
  }

  &:disabled {
    opacity: .5;
    pointer-events: none;
  }

  cursor: pointer;
}

button.buttonDisabled {
  height: 4rem;
  font-family: 'Gellix-Medium', sans-serif;
  background-color: #d3d3d3;
  opacity: .4;
  border: none;
  border-radius: var(--borderRadius);
  color: #000;

  cursor: pointer;

  pointer-events: none;
}

input.textInput {
  border: solid #DEDDDB .1rem;
  border-radius: var(--borderRadius);

  background-color: transparent;

  color: #000;
  font-family: 'Gellix-Medium', sans-serif;
  font-size: 1.6rem;

  padding: 2rem 1.5rem;
  width: 100%;
}

button.buttonLink {
  font-size: var(--fs-text);
  font-family: 'Gellix-Regular', sans-serif;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
  background: none;
  border: none;
}

a {
  font-size: var(--fs-text);
  font-family: 'Gellix-Regular', sans-serif;
  color: var(--primary);
  text-decoration: none;
  cursor: pointer;
}

.textDisabled {
  font-family: 'Gellix-Medium', sans-serif;
  color: #d3d3d3;
  opacity: .5;

  cursor: pointer;

  pointer-events: none;
}

.countDown,
.discountPackageBanner {
  display: flex;
  flex-direction: column;
  justify-content: center;

  color: #fff;
  background: #FD7E14;

  width: 100%;
  height: 2.5rem;
  width: 100%;

  text-align: left;
  padding: 1rem 0 1rem 4rem;



  @include sm {
    padding: 1rem 0 1rem 1rem;
  }
}

.discountPackageBanner {
  background-color: var(--primary);
  padding: 3rem 0 3rem 4rem;



  @include sm {
    padding: 3rem 0 3rem 1rem;
  }
}

.overlayNav {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 999;
  pointer-events: all;
}