@use "../LeftBox";
@import "../Mixins";

.receipt {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100vw;

    margin-top: 5rem;

    // min-height: min-content;

    &-container {
        display: grid;
        grid-template-columns: 440px 1fr;
        grid-template-rows: 1fr;
        position: relative;
        height: 100%;
        width: 100%;
        margin: 0 auto;
        z-index: 1;

        @include xl {
            grid-template-rows: 1fr;
        }

        @include sm2 {
            grid-template-columns: 1fr;
        }

        &_imgOverlay {
            grid-column: 2 / 2;
            grid-row: 1 / -1;
            z-index: 1;
            position: relative;

            @include sm2 {
                display: none;
            }

            &--img {
                height: 100%;
                width: 100%;
                margin: 0;

                object-fit: cover;
                object-position: center;
            }

            &::after {
                content: '';
                position: absolute;
                left: 0;
                top: 0;
                width: 100%;
                height: 100%;
                display: inline-block;
                background: linear-gradient(90deg, rgba(0, 0, 0, .4) 0%, rgba(0, 0, 0, .3) 11%, rgba(26, 177, 33, 0) 100%, rgba(181, 13, 30, 0) 100%);
            }
        }

    }
}