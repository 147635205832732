@import "./Mixins";

.Nav {
    position: fixed;
    top: 0;
    z-index: 9999;

    background-color: var(--NavColor);
    width: 100vw;
    height: 5rem;
    min-height: 5rem;




    box-shadow: var(--shadow1);

    .arrowBackButton {
        display: flex;
        flex-direction: row;
        gap: 2rem;
        color: #fff;
        align-items: center;
        // justify-content: center;

        cursor: pointer;
    }

    &_container {
        display: flex;
        justify-content: space-between;
        width: 100vw;
        height: 100%;
        align-items: center;

        margin: 0 3.5rem;

        @include sm {
            margin: 0 1.5rem;
        }

        .left {
            display: flex;
            flex-direction: row;
            gap: 5rem;

            @include sm {
                gap: 1.5rem;
            }
        }

        &-teamInfo {
            display: flex;
            flex-direction: row;
            gap: 1rem;

            cursor: pointer;

            background-color: transparent;

            border: none;

            align-items: center;
            justify-content: center;


            color: #fff;

            min-height: 2.5rem;

            &_matchInfo {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: flex-start;

                h4 {
                    max-width: 60vw;

                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    font-size: 1.4rem;
                    font-weight: 300;
                    font-family: 'Gellix-Regular', sans-serif;
                }

                p {
                    font-size: 1.2rem;
                    font-weight: 300;
                    font-family: 'Gellix-Regular', sans-serif;
                }
            }


            h2 {
                font-weight: 300;
                font-size: var(--fs-text);
                font-family: 'Gellix-Regular', sans-serif;

                @include sm {
                    font-size: 1.2rem;
                }
            }
        }

        &-logo {
            height: 1.4rem;
        }

        &-nameBtnWrap {
            position: relative;

            // margin-right: 10rem;
            cursor: pointer;

            @include sm {
                padding-left: 5rem;
                height: 100%;
            }

            &_btn {
                background-color: transparent;
                cursor: pointer;

                color: #fff;

                border: none;

                display: flex;
                flex-direction: row;
                align-items: center;
                height: 100%;
                gap: 1rem;
                text-decoration: none;

                margin-right: 6.5rem;

                @include sm {
                    margin-right: 3.5rem;
                }


                &--logo {
                    fill: #fff;
                }

                &--name {
                    cursor: pointer;
                    color: #fff;
                    font-weight: 700;
                    font-size: var(--fs-text);
                    font-family: 'Gellix-Regular', sans-serif;

                    @include sm() {
                        font-size: 1.2rem;
                    }
                }
            }
        }

        // &-nameBtnWrap:hover &-userContainer {
        //     display: grid;
        // }


    }
}

.userContainer {
    display: flex;
    flex-direction: column;
    justify-content: space-around;

    position: fixed;
    top: 6.5rem;
    right: 3rem;

    background-color: transparent;

    z-index: 9999;

    width: 25rem;
    height: fit-content;

    filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.1));


    @include md {
        top: 5rem;
        left: 0;
        height: 100%;
        width: 100%;

        justify-content: flex-start;
        padding: 2rem;
        background-color: #fff;

        gap: 2rem;

        filter: none;
    }

    button {
        cursor: pointer;
    }

    &-header {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        align-items: flex-start;
        justify-content: center;

        button {
            background-color: transparent;

            border: none;

            svg {
                height: 3.5rem;
                width: 3.5rem;
            }
        }
    }

    &-options {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: center;


        & button:first-child {
            border-top-left-radius: 25px;
            border-top-right-radius: 25px;
            -webkit-border-top-left-radius: 25px;
            -webkit-border-top-right-radius: 25px;

            @include md {
                border-radius: var(--borderRadius);
            }
        }

        & button:last-child {
            border-bottom-left-radius: 25px;
            border-bottom-right-radius: 25px;
            -webkit-border-bottom-left-radius: 25px;
            -webkit-border-bottom-right-radius: 25px;

            @include md {
                border-radius: var(--borderRadius);
            }
        }

        @include md {
            gap: 2rem;
        }

        button {
            width: 100%;
            text-align: left;

            padding: 2rem 1.5rem;

            background-color: #fff;

            display: flex;
            align-items: center;
            justify-content: space-between;

            font-family: 'Gellix-SemiBold', sans-serif;
            font-size: 16px;
            font-weight: 700;

            transition: all .2s ease;

            border: solid .1rem transparent;

            @include md {
                background-color: #dddddd;
            }

            span.info {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin: auto 0;
                gap: 1rem;

                svg {
                    margin-top: .2rem;
                    height: 2rem;
                    width: 2rem;
                }
            }

            span.rightArrow {
                display: none;
            }

            @include md {
                span.rightArrow {
                    display: block;
                }
            }

            &:hover {
                background-color: #F1F3F7;
            }
        }
    }

}