.ticketChooserLoader {
    width: 100%;
    margin: auto;
    height: 100%;

    div {
        justify-content: center;
        display: flex;
        align-items: center;
        height: 100%;
    }
}
.loading-full {
    position: fixed;
    top: 0;
    left: 0;
    right: 100vw;
    bottom: 100vh;
    width: 100vw;
    height: 100vh;
    z-index: 2000;
    background-color: rgba(0,0,0,.5);
    display: flex;
    justify-content: center;
    align-items: center;
  
    svg {
      z-index: 2001;
      animation: refresh 1s infinite linear;
    }
}
