@use "./MobileButtons";
@import "./Mixins";

.Tickets-container_bottomBox,
.section-container_bottomBox,
.rows-container_bottomBox {

    height: 100%;
    width: 100vw;
    grid-column: 1 / -1;
    grid-row: 2 / 2;
    align-self: end;
    z-index: 9999;

    background-color: #fff;
    box-shadow: var(--shadow4);

    padding-left: 2rem;

    @include xl() {
        background-color: transparent;
        padding: 0;

        grid-column: 1 / 1 !important;
        grid-row: 2 / 2;

        display: flex;
        flex-direction: row;
        align-content: center;
        justify-content: space-between;
        align-items: center;
    }

    @include sm() {
        height: min-content;
        position: fixed;
        bottom: 0;
    }

    @include sm2() {
        justify-content: center;
    }

    .TicketOrder {
        height: 100%;
        display: grid;
        grid-template-columns: 440px .5fr 100px 1fr;
        grid-template-rows: 1fr;

        &-btn {
            grid-column: 1 / 1;
            grid-row: 1 / 1;
            place-self: center;

            font-family: 'Gellix-Semibold', sans-serif;
            font-size: var(--fs-text);

            width: 40.9rem;
            height: 4.8rem;
            border: none;
            border-radius: 1rem;

            transition: background-color .2s ease;
            text-decoration: none;

            display: flex;
            justify-content: center;
            align-items: center;

            margin: 0rem 1rem;

        }


        .NONE {
            background-color: transparent;
            color: #848484;

            border: solid #DEDDDB .1rem;
            border-radius: .6rem;

            &:focus {
                border: solid .2rem #000;
            }

            @include sm() {
                background-color: #fff;
            }
        }

        .ОК {
            background-color: var(--primary);
            color: #fff;

            &:hover {
                background-color: var(--primary-hover);
            }
        }

        &-userOrder {
            align-self: center;
            justify-self: flex-start;

            grid-column: 2 / 2;
            grid-row: 1 / 1;

            display: flex;
            flex-direction: column;
            gap: .5rem;
            margin-left: 2rem;

            &--title {
                font-family: 'Gellix-Semibold', sans-serif;
                font-size: var(--fs-text-big);
                color: var(--textColor);
            }

            &--teams {
                font-family: 'Gellix-Medium', sans-serif;
                font-size: var(--fs-text);
                color: var(--textColor);
            }

            &--date {
                font-family: 'Gellix-Medium', sans-serif;
                font-size: var(--fs-text);
                color: var(--textColor);
            }
        }

        &-sum {
            grid-column: 3 / 3;
            grid-row: 1 / 1;

            display: grid;
            grid-template-rows: repeat(3, .5fr);
            grid-auto-columns: minmax(max-content, 1fr);
            column-gap: .5rem;
            grid-auto-flow: column;
            justify-self: flex-start;
            align-self: center;

            &--quantityTitle {
                align-self: center;
                grid-column: 1 / 1;
                grid-row: 1 / 1;
                font-family: 'Gellix-Semibold', sans-serif;
                font-size: var(--fs-text-big);
                color: var(--textColor);
            }

            &--quantity {
                align-self: end;
                font-family: 'Gellix-Semibold', sans-serif;
                font-size: var(--fs-text);
                color: var(--textColor);
            }
        }

        &-price {
            grid-column: 4 / 4;
            grid-row: 1 / 1;

            display: flex;
            flex-direction: column;
            gap: .5rem;
            justify-self: end;
            align-self: center;

            margin-right: 5rem;

            &--title {
                font-family: 'Gellix-Medium', sans-serif;
                font-size: var(--fs-text);
                color: var(--textColor);
                text-align: center;
            }

            &--pricing {
                font-family: 'Gellix-Bold', sans-serif;
                font-size: var(--fs-heading);
                color: var(--textColor);
            }
        }
    }

    .btnWrap {
        display: grid;
        grid-row-gap: 2rem;
        row-gap: 2rem;
        position: absolute;
        right: 2.5rem;
        bottom: 10rem;
        justify-items: center;
    }

    .NONE {
        background-color: transparent;
        color: #848484;

        border: solid #DEDDDB .1rem;
        border-radius: .6rem;

        &:focus {
            border: solid .2rem #000;
        }

        @include sm() {
            background-color: #fff;
        }
    }

    .ОК {
        background-color: var(--primary);
        color: #fff;

        &:hover {
            background-color: var(--primary-hover);
        }
    }
}