@font-face {
    font-family: "Gellix-Black";
    src: local("Gellix-Black");
    src: url("../../fonts/Gellix-Black.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-Bold";
    src: local("Gellix-Bold");
    src: url("../../fonts/Gellix-Bold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-SemiBold";
    src: local("Gellix-SemiBold");
    src: url("../../fonts/Gellix-SemiBold.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-Regular";
    src: local("Gellix-Regular");
    src: url("../../fonts/Gellix-Regular.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-Medium";
    src: local("Gellix-Medium");
    src: url("../../fonts/Gellix-Medium.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-Light";
    src: local("Gellix-Light");
    src: url("../../fonts/Gellix-Light.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Gellix-Thin";
    src: local("Gellix-Thin");
    src: url("../../fonts/Gellix-Thin.otf") format("opentype");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}