.PhoneInput {
	height: 5rem;
	.PhoneInputCountry {
		height: 100%;
		border: 0.1rem solid #dedddb;
		margin: 0;
		width: 6rem;
		display: flex;
		justify-content: center;
		border-radius: var(--borderRadius) 0 0 var(--borderRadius);
	}
	.PhoneInputInput {
		height: inherit;
		padding: 2rem 1.5rem;
		border: 0.1rem solid #dedddb;
		border-left: 0;
		border-radius: 0 var(--borderRadius) var(--borderRadius) 0;
	}
	.PhoneInputCountryIcon {
		height: 30%;
		width: auto;
		box-shadow: none;
	}
	.PhoneInputCountrySelectArrow {
		width: 0.6rem;
		height: 0.6rem;
		margin-left: 0.8rem;
	}
}