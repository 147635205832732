@import "../Mixins";

.fieldTypes {
    cursor: pointer;
    // max-width: 50%;

    &-titleWrap {
        display: flex;
        flex-direction: row;
        align-items: center;
        gap: 1rem;

        padding: 1rem;
        border-radius: 1.25rem;

        &_icon {
            width: 2rem;
            height: 2rem;
            font-size: 2rem;

            border-radius: 50%;

            display: grid;
            place-items: center;
        }

        &_title {
            font-family: 'Gellix-Regular', sans-serif;
        }
    }
}

.dropDown {
    transition: height .2s ease-in;
    height: 0;
    overflow-y: hidden;
    overflow-x: hidden;

    display: flex;
    flex-direction: column;
    gap: 1rem;


    &_fieldCard {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: min-content;

        height: auto;
        background-color: #F1F3F7;
        border: none;
        border-radius: 1rem;

        cursor: pointer;

        .arrowContainer {
            position: absolute;
            bottom: .5rem;
            right: .5rem;
            font-size: var(--fs-title);
        }

        &--picture {
            display: flex;
            align-items: center;
            justify-content: center;

            border-top-left-radius: 1rem;
            border-bottom-left-radius: 1rem;

            height: 100%;

            overflow: hidden;

            &_photo {
                align-self: center;
                height: auto;
                width: 100%;
            }
        }

        &--cardInfo {
            height: 100%;
            display: flex;
            flex-direction: column;
            text-align: left;

            // padding: 1.5rem;
            // border-left: solid .2rem grey;

            align-self: center;


            &-title {
                align-self: flex-start;
                width: max-content;
                height: max-content;
                font-family: 'Gellix-Regular', sans-serif;
            }


            &-seatsLeft {
                height: max-content;
                display: flex;
                align-items: center;

                font-family: 'Gellix-SemiBold', sans-serif;
                font-size: 12px;
                letter-spacing: .05rem;
                color: var(--primary);

                margin-top: -.5rem;
                margin-bottom: 1rem;

                & span {
                    margin-right: .5rem;
                    margin-bottom: .75rem;
                    color: var(--primary);
                    font-size: var(--fs-title);
                }
            }

            &-totalPrice {
                font-family: 'Gellix-Regular', sans-serif;
                width: max-content;
            }
        }

        .notAvailable {
            align-items: center;
            justify-content: center;
            grid-column: 2 / 2;
            width: 100%;
            // padding: 1.5rem;
        }

        .notAvailableTitle {
            width: 100%;
            // font-size: var(--fs-text-big);
            font-size: 1.6rem;
        }

        &:first-child {
            margin-top: 1rem;
        }
    }


    &_fieldCardDisabled {
        cursor: not-allowed;

        &:first-child {
            margin-top: 1rem;
        }
    }
}

.isOpen {
    height: 100%;
}