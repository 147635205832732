@use "./PriceGroupPage/_TicketChooserLeftBox.scss";
@use "./FieldPage/_FieldsLeftBox.scss";
@use "./RowPage/_RowsLeftBox.scss";
@use "./SeatPage/_SeatsLeftBox.scss";
@use "./PaymentPage/_PaymentLeftBox.scss";
@use "./ReceiptPage/_ReceiptLeftBox.scss";
@import "./Mixins";

.Tickets-container_leftBox,
.section-container_leftBox,
.rows-container_leftBox,
.seats-container_leftBox,
.payment-container_leftBox,
.receipt-container_leftBox,
.checkingPayment_leftBox {
    box-shadow: 5px 0px 15px rgba(0, 0, 0, 0.08);

    height: 100%;
    width: 44rem;
    grid-column: 1 / 1;
    grid-row: 1 / 1;

    @include sm2 {
        width: 100vw;
    }

    overflow-y: auto;
    overflow-x: hidden;

    &-wrap {

        display: flex;
        flex-direction: column;
        gap: 2rem;
    }


    &-topSection {

        box-shadow: 0 2px 13px -2px rgba(0, 0, 0, .2);

        padding-bottom: 1rem;

        .paddingNavigator {
            padding-left: 4rem;
            padding-right: 2rem;

            @include sm {
                padding: var(--pagePaddingSidesMobile);
            }
        }
    }

    &-bottomSection {
        grid-row: 2 / 2;
        grid-column: 1 / 1;

        padding-left: 4rem;
        padding-right: 2rem;

        @include sm {
            margin-bottom: 12rem;
        }

        @include sm {
            padding: var(--pagePaddingSidesMobile);
        }

        margin-bottom: 5rem;

        display: grid;
        grid-template-columns: 1fr;
        grid-auto-rows: min-content;
        grid-row-gap: 1rem;
        row-gap: 1rem;

    }
}

.Tickets-container_leftBox-bottomSection {
    grid-row: 2 / 2;
    grid-column: 1 / 1;
    display: flex;
    flex-direction: column;
    grid-row-gap: 3rem;
    row-gap: 3rem;
}

.Tickets-container,
.section-container,
.rows-container,
.seats-container,
.payment-container,
.receipt-container,
.checkingPayment-container {
    @include sm {
        position: relative;
        grid-template-rows: 1fr 80px;
    }

    &_imgOverlay {
        height: calc(100vh - 5rem);
    }
}