@import "../Mixins";

.rowCard {
    position: relative;
    display: grid;
    grid-template-rows: 1fr;
    grid-template-columns: max-content 1fr;
    align-items: center;

    padding: .75rem 1.5rem;

    background-color: rgb(227, 236, 241);

    border: none;
    border-radius: var(--borderRadius);

    cursor: pointer;

    &:last-child {
        margin-bottom: 1rem;
    }

    &-title {
        grid-column: 1 / 1;
        grid-row: 1 / 1;
        justify-self: flex-start;

        font-family: 'Gellix-Regular', sans-serif;
        font-size: var(--fs-text-big);
    }

    &-seatsLeft {
        grid-column: 2 / 2;
        grid-row: 1 / 1;
        justify-self: end;

        // height: max-content;
        display: flex;
        align-items: center;

        font-family: 'Gellix-SemiBold', sans-serif;
        letter-spacing: .05rem;
        color: var(--primary);

        & span {
            margin-right: .5rem;
            margin-bottom: .75rem;
            color: var(--primary);
            font-size: var(--fs-title);
        }
    }

    .arrowContainer {
        margin-top: .2rem;
        display: flex;
        align-items: center;

        grid-column: 2 / 2;
        grid-row: 1 / 1;
        justify-self: end;

        font-size: var(--fs-text-big);
    }
}

.disabled {
    cursor: auto;
    opacity: .7;
}