@import "../Mixins";

.receipt-container_leftBox-topSection {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    align-items: center;

    &--icon {
        margin-top: 5.5rem;
        height: 5rem;
        width: 5rem;
    }

    &--title {
        font-size: var(--fs-title);
        font-family: 'Gellix-Bold', sans-serif;
        text-align: center;
        margin-top: 1.5rem;
        width: 80%;
    }
}

.order {
    display: flex;
    flex-direction: column;
    gap: 2.3rem;

    padding-bottom: 2rem;
    border-bottom: .2rem solid #F1F3F7;

    margin-top: 4.1rem;

    &-title {
        font-size: var(--fs-text-big);
        font-family: 'Gellix-Regular', sans-serif;
    }

    &-description {
        color: var(--darkGray);
        font-family: 'Gellix-Regular', sans-serif;
        font-size: var(--fs-text);

        &_teams {}

        &_date {}
    }

    &-orderId {
        color: var(--darkGray);
        font-family: 'Gellix-Regular', sans-serif;
        font-size: var(--fs-text);
    }

    &-pay {
        color: var(--darkGray);
        font-family: 'Gellix-Regular', sans-serif;
        font-size: var(--fs-text);

        &_date {}

        &_method {}
    }


}

.ticketReceiptPDF {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;

    margin-top: 3rem;

    h1 {
        font-size: var(--fs-text-big);
        font-family: 'Gellix-Regular', sans-serif;
    }

    a {
        display: flex;
        align-items: center;
        justify-content: center;
    }

}

.tickets {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 2rem;

    &-title {
        font-size: var(--fs-text-big);
        font-family: 'Gellix-Regular', sans-serif;
    }

    &-tickets {
        display: flex;
        flex-direction: column;
        gap: 1rem;

        &_ticket {
            display: flex;
            justify-content: space-between;
            flex-direction: row;

            &--title {
                font-size: var(--fs-text);
                font-family: 'Gellix-Regular', sans-serif;
            }

            &--price {
                font-size: var(--fs-text);
                font-family: 'Gellix-Regular', sans-serif;
            }
        }
    }
}

.ticketSeats {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    padding-bottom: 4.5rem;

    &-title {
        font-size: var(--fs-text-big);
        font-family: 'Gellix-Regular', sans-serif;
    }

    &-seat {
        font-size: var(--fs-text);
        font-family: 'Gellix-Regular', sans-serif;
    }
}

.totalPrice {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 2rem;

    font-size: var(--fs-text);
    font-family: 'Gellix-Bold', sans-serif;

    color: var(--darkGray);
}

.ticketPageBtn {
    margin-top: 4.6rem;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 2rem;

    font-size: 1.8rem;
    color: #fff;
    font-family: 'Gellix-Bold', sans-serif;

    background-color: var(--primary);

    height: 5rem;

    border: none;
    border-radius: 1rem;

    cursor: pointer;

    transition: background-color .2s ease;

    &:hover {
        background-color: var(--primary-hover);
    }

    &-icon {
        height: 2.5rem;
        width: 2.5rem;
    }
}