@import "./Mixins";

.AcceptTeamRegistration {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);

    &-box {
        z-index: 999999999;
        position: absolute;
        top: 50%;
        bottom: 50%;
        left: 50%;
        right: 50%;
        background-color: #fff;
        height: min-content;
        width: 500px;
        transform: translate(-50%, -50%);

        padding: 4rem;

        border-radius: var(--borderRadius);

        display: flex;
        flex-direction: column;
        gap: 2rem;
        justify-content: flex-start;
        align-items: flex-start;

        @include sm() {
            width: 250px;
            padding: 2.5rem;
            gap: 3rem;
        }

        & .buttons {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            gap: 5rem;
            align-items: center;

            @include sm() {
                flex-direction: column;
                gap: 2rem;
                width: fit-content;
                margin: 0 auto;
            }

            button {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding: 2rem;
                width: fit-content;

                @include sm() {
                    width: 100%;
                }
            }
        }
    }
}