@import "./Mixins";

.TeamsPage {
    width: 100vw;

    padding-bottom: 5rem;

    min-height: fit-content;

    &-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        width: 100%;
        z-index: 1;

        &_loader {
            grid-row: 3 / 3;

            place-self: center;

            margin-top: 5rem;
        }

        &_terms {
            display: flex;
            flex-direction: row;
            gap: 2rem;
            align-items: center;

            justify-content: center;

            text-align: center;

            padding: 3rem 0rem;
        }


        &_banner {
            width: 100vw;
            height: 31rem;

            display: grid;
            grid-template-columns: 1fr;
            grid-template-rows: inherit;
            place-items: center;
            place-self: center;

            object-fit: cover;
            object-position: top;
            background-size: cover;
            background-attachment: fixed;
            background-repeat: no-repeat;

            &-titleWrap {
                grid-column: 1 / -1;
                grid-row: 1 / 1;
                align-self: end;

                display: flex;
                width: 100%;
                max-width: var(--max-width);
                justify-content: space-between;

                margin-bottom: 3rem;

                &--title {
                    color: #fff;
                    font-size: var(--fs-heading-big);
                    font-family: 'Gellix-Bold', sans-serif;
                    letter-spacing: .1rem;
                    font-weight: 500;

                    @include lg() {
                        font-size: var(--fs-heading);

                        padding: var(--pagePaddingSides);

                    }

                    @include sm() {
                        font-size: var(--fs-title);
                    }
                }
            }

            &-img {
                grid-column: 1 / -1;
                grid-row: 1 / 1;
                place-self: center;
                height: 100%;
                width: 100vw;
                object-fit: cover;
                object-position: top;
                background-size: cover;
                background-attachment: fixed;
                background-repeat: no-repeat;
            }
        }


        &_matches {
            height: 7rem;
            width: 100vw;

            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            flex-wrap: nowrap;

            place-self: center;
            padding: 1.5rem 0;


            &-header {
                grid-row: 2 / 2;
                display: flex;
                justify-content: space-between;
                align-items: center;
                max-width: var(--max-width);
                width: 100%;

                @include lg() {
                    padding: var(--pagePaddingSides);
                }

                & h1 {
                    font-size: var(--fs-heading);
                    font-family: 'Gellix-SemiBold', sans-serif;
                    font-weight: 300;


                    @include sm() {
                        font-size: 1.75rem;
                    }
                }

                &--optionList {
                    cursor: pointer;
                    padding: 1rem;
                    border: 1px solid #e7e2e2
                }
            }
        }

        &_matchList {
            grid-row: 3 / 3;
            place-items: center;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            justify-content: flex-start;
            align-items: stretch;
            width: 100%;
            max-width: var(--max-width);
            row-gap: .75rem;

            margin: 0 auto;

            @include lg() {
                padding: var(--pagePaddingSides);
            }

            @include md() {
                padding: 0rem .75rem;
            }

            &-match {
                display: grid;
                grid-template-columns: 120px 1fr 220px;
                grid-template-rows: 1fr .75fr;
                row-gap: .75rem;

                min-height: 12rem;
                width: 100%;
                align-items: stretch;
                background-color: #ffff;
                box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.1);

                border-radius: var(--borderRadius);

                @include lg() {
                    // grid-template-rows: 1fr .5fr 1fr 1fr;
                    grid-template-columns: 120px 1fr 50px;
                    row-gap: .5rem;
                }

                @include sm() {
                    grid-template-columns: 50px 1fr 30px;
                }

                transition: box-shadow .2s ease-in;

                &:hover {
                    box-shadow: var(--shadow4);

                    .TeamsPage-container_matchList-match_ticketBtn {
                        background-color: var(--primary-hover);
                    }
                }

                &_leftBox {
                    display: flex;
                    flex-direction: column;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;

                    height: 100%;
                    align-self: center;
                    grid-column: 1 / 1;
                    grid-row: 1 / -1;

                    border-right: .1rem solid #D8D8D8;
                    color: #000;

                    &--date {
                        font-size: var(--fs-heading-big);
                        font-family: 'Gellix-SemiBold', sans-serif;
                        font-weight: 300;
                        text-transform: uppercase;

                        @include sm() {
                            font-size: var(--fs-title);
                        }
                    }

                    &--month {
                        font-size: var(--fs-text-big);
                        font-family: 'Gellix-SemiBold', sans-serif;
                        font-weight: 300;
                        text-transform: uppercase;

                        @include sm() {
                            font-size: var(--fs-text);
                        }
                    }
                }

                &_teamName {
                    display: inline;
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;
                    align-self: end;
                    justify-self: flex-start;
                    margin-left: 2rem;

                    font-size: var(--fs-title);
                    font-family: 'Gellix-SemiBold', sans-serif;
                    font-weight: 400;
                    color: #000;

                    text-transform: capitalize;

                    @include md() {
                        font-size: var(--fs-text-big);
                    }

                    @include sm() {
                        font-size: var(--fs-text);
                    }
                }

                &_startDate {
                    display: flex;
                    flex-direction: row;
                    gap: 2rem;

                    grid-column: 2 / 2;
                    grid-row: 2 / 2;

                    align-self: flex-start;
                    justify-self: flex-start;
                    align-items: center;

                    @include sm() {
                        gap: .5rem
                    }

                    &--date {
                        margin-left: 2rem;

                        font-size: var(--fs-text);
                        font-family: 'Gellix-Medium', sans-serif;
                        font-weight: 300;
                        color: #646464;

                        @include sm() {
                            font-size: 1.2rem;
                        }
                    }
                }

                &_filters {
                    grid-column: 2 / 2;
                    grid-row: 1 / 1;
                    align-self: flex-start;
                    justify-self: flex-start;

                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    column-gap: 1rem;

                    margin-top: 1rem;
                    margin-left: 2rem;

                    &-today {
                        background-color: var(--primary-red);
                        color: #fff;
                        padding: .25rem 1.25rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        @include sm() {}
                    }

                    &-fewTickets {
                        background-color: var(--primary-orange);
                        color: #fff;
                        padding: .25rem 1.25rem;
                        border-radius: .5rem;
                        font-family: 'Gellix-Bold', sans-serif;

                        text-transform: uppercase;
                        font-size: 1.1rem;

                        @include sm() {}
                    }
                }


                &_ticketBtn {
                    cursor: pointer;
                    display: flex;
                    flex-direction: row;
                    flex-wrap: nowrap;
                    align-items: center;
                    justify-content: center;
                    gap: 1rem;

                    grid-column: 3 / 3;
                    grid-row: 1 / -1;

                    place-self: center;

                    background-color: var(--primary);
                    height: 5rem;
                    width: 17rem;

                    border: none;
                    border-radius: var(--borderRadius);

                    transition: background-color .2s ease-in;

                    &--text {
                        font-size: var(--fs-text-big);
                        font-family: 'Gellix-Bold', sans-serif;
                        font-weight: 300;
                        color: #fff;
                    }

                    &--img {
                        height: 2rem;
                        width: 2rem;
                    }
                }

                &_notActive {
                    font-size: var(--fs-text);
                    font-weight: 300;
                    color: #000;

                    grid-column: 3 / 3;
                    grid-row: 1 / -1;

                    place-self: center;
                }

                &_mobileArrow {
                    grid-row: 1 / -1;
                    grid-column: 3 / 3;
                    place-self: center;

                    height: 1rem;
                    transform: rotate(-90deg);

                    @include sm() {
                        height: .75rem;
                    }
                }
            }

            &-matchLink {
                margin-top: 1rem;
                text-decoration: none;
                width: 100%;
            }
        }

        &_noResult {
            margin-top: 5rem;

            display: flex;
            flex-direction: column;
            flex-wrap: nowrap;
            gap: 2rem;

            grid-row: 3 / 3;
            align-self: flex-start;
            justify-self: center;
            text-align: center;

            &--text {
                font-size: var(--fs-title);
            }

            // &--link {
            //     font-size: var(--fs-text);
            // }
        }
    }
}